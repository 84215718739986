<template>
  <div>
    <CyDetails
      :save-btn-text="$isCreationRoute ? $t('createWatchRule') : $t('forms.btnSave')"
      :can-cancel="$isCreationRoute || $hasDataChanged('formContent')"
      :on-cancel="onCancel"
      :can-save="canSave"
      :on-save="onSave"
      :saving="saving"
      :on-delete="$toggle.showDeleteDialog"
      :deleting="deleting"
      :loading="loading">
      <template #details_formFullWidth>
        <v-container
          v-if="!selectedWatchRuleType"
          class="px-0 pt-6"
          fluid>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.type.title')"/>
            </v-col>
            <v-col cols="6">
              <ul class="watch-rule-types">
                <li
                  v-for="watchRuleType in $static.watchRuleTypes"
                  :key="watchRuleType.key"
                  :aria-label="watchRuleType.label"
                  class="watch-rule-types__item mb-2"
                  @click="selectedWatchRuleType = watchRuleType">
                  <CyAvatar
                    :item="{ icon: watchRuleType.icon, color: watchRuleType.color }"
                    class="mr-4"/>
                  <div>
                    <div
                      class="h6"
                      v-text="watchRuleType.name"/>
                    <div v-text="watchRuleType.description"/>
                  </div>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          v-else
          class="px-0 pt-6"
          fluid>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.general.title')"/>
            </v-col>
            <v-col cols="6">
              <label
                class="watch-rule-type__label"
                v-text="$t('watchRuleType')"/>
              <ul class="watch-rule-types--selected">
                <li
                  :aria-label="selectedWatchRuleType.label"
                  class="watch-rule-types__item my-1">
                  <CyAvatar
                    :item="{ icon: selectedWatchRuleType.icon, color: selectedWatchRuleType.color }"
                    class="mr-4"/>
                  <div>
                    <div
                      class="h6"
                      v-text="selectedWatchRuleType.name"/>
                    <div v-text="selectedWatchRuleType.description"/>
                  </div>
                </li>
              </ul>
              <button
                v-if="$isCreationRoute"
                class="change-type__btn"
                @click="changeType"
                v-text="$t('changeType')"/>
              <v-text-field
                v-model="$v.formContent.name.$model"
                :label="$t('fields.ruleName.label')"
                :hint="$t('fields.ruleName.hint')"
                :error-messages="nameErrors"
                persistent-hint
                class="mb-6 mt-4 required-field"
                @blur="$v.formContent.name.$touch()"/>
              <v-select
                v-model="$v.formContent.organization.$model"
                :items="organizations"
                item-text="name"
                item-value="canonical"
                :error-messages="organizationErrors"
                :label="$t('fields.organization.label')"
                :hint="$t('fields.organization.hint')"
                :readonly="!$isCreationRoute"
                persistent-hint
                class="mb-6 required-field"
                @blur="$v.formContent.organization.$touch()">
                <template #selection="{ item }">
                  <CyAvatar
                    class="mr-2 my-2"
                    :item="item"
                    sm/>
                  {{ item.name }}
                </template>
                <template #item="{ item, attrs, on }">
                  <v-list-item
                    role="option"
                    class="px-1"
                    v-bind="attrs"
                    v-on="on">
                    <v-list-item-avatar class="mr-2">
                      <CyAvatar
                        :item="item"
                        sm/>
                    </v-list-item-avatar>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-if="selectedWatchRuleType.key === 'projectActivity'"
                v-model="$v.formContent.project.$model"
                :items="projects"
                item-text="name"
                item-value="canonical"
                :error-messages="projectErrors"
                :label="$t('fields.project.label')"
                :hint="$t('fields.project.hint')"
                :readonly="!$isCreationRoute"
                persistent-hint
                class="mb-6 required-field"
                @blur="$v.formContent.project.$touch()">
                <template #selection="{ item }">
                  <v-icon class="mr-2 my-2">
                    folder_open
                  </v-icon>
                  {{ item.name }}
                </template>
                <template #item="{ item, attrs, on }">
                  <v-list-item
                    role="option"
                    class="px-3"
                    v-bind="attrs"
                    v-on="on">
                    <v-list-item-icon class="mr-3">
                      <v-icon>folder_open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
              <v-switch
                v-model="$v.formContent.muted.$model"
                color="secondary"
                :label="$t('fields.mute.label')"
                :hint="$t('fields.mute.hint')"
                persistent-hint/>
            </v-col>
          </v-row>
          <v-divider class="my-8"/>
          <v-row
            align="start"
            justify="start"
            no-gutters>
            <v-col cols="3">
              <h3 v-text="$t('sections.configuration.title')"/>
            </v-col>
            <v-col cols="6">
              <div>
                <CyTag>
                  {{ $t('when') }}
                </CyTag>
                {{ $t('eventIsFired') }}
              </div>
              <div class="d-flex my-4">
                <CyFilterTagsList
                  v-model="selectedFilters"
                  :filter-icons="$static.filterIcons"/>
                <CyFilterTree
                  v-model="selectedFilters"
                  :filter-tree="filterTree"/>
              </div>
              <div>
                <CyTag>
                  {{ $t('then') }}
                </CyTag>
                {{ $t('sendNotification') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :loading="deleting"
      :action-btn-func="onDeleteConfirm"
      :action-btn-disabled="deleting"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>{{ $t('confirmDeleteSentence') }}</p>
      <h3>{{ watchRule.name }}</h3>
      <p class="url">
        {{ watchRule.canonical }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CyDetails from '@/components/details.vue'
import CyFilterTagsList from '@/components/filter-tags-list.vue'
import CyFilterTree from '@/components/filter-tree.vue'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, requiredIf } from 'vuelidate/lib/validators'

const EMPTY_FORM_CONTENT = {
  name: '',
  organization: '',
  project: '',
  muted: false,
}

export default {
  name: 'CyPageUserWatchRule',
  components: {
    CyDetails,
    CyFilterTagsList,
    CyFilterTree,
  },
  breadcrumb () {
    const header = this.$isCreationRoute ? this.$t('createWatchRule') : this.watchRule?.name

    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  header () {
    const title = this.$isCreationRoute ? this.$t('createWatchRule') : this.watchRule?.name

    return {
      title,
      ...(
        this.$isCreationRoute
          ? {
              description: {
                text: this.$t('description'),
              },
            }
          : {}
      ),
    }
  },
  validations () {
    const { selectedWatchRuleType } = this
    return {
      formContent: {
        name: { required },
        organization: { required },
        project: {
          required: requiredIf(() => selectedWatchRuleType?.key === 'projectActivity'),
        },
        muted: {},
      },
    }
  },
  data: () => ({
    selectedWatchRuleType: null,
    formContent: _.cloneDeep(EMPTY_FORM_CONTENT),
    selectedFilters: [],
    saving: false,
    loading: true,
    deleting: false,
    showDeleteDialog: false,
  }),
  computed: {
    ...mapState(['organizations']),
    ...mapState('organization', {
      projects: (state) => state.available.projects,
      tags: (state) => state.available.tags,
    }),
    ...mapState('notifications', {
      watchRule: (state) => state.watchRule,
    }),
    $static () {
      return {
        watchRuleTypes: [
          {
            label: 'Project activity',
            name: this.$t('watchRuleTypes.project.name'),
            description: this.$t('watchRuleTypes.project.description'),
            icon: 'commit',
            color: 'prod',
            key: 'projectActivity',
          },
          {
            label: 'Event tracking',
            name: this.$t('watchRuleTypes.event.name'),
            description: this.$t('watchRuleTypes.event.description'),
            icon: 'podcasts',
            color: 'staging',
            key: 'eventTracking',
          },
        ],
        filterIcons: {
          Action: 'mdi-lightning-bolt',
          Entity: 'mdi-shape',
        },
      }
    },
    nameErrors () {
      const { $dirty, required } = this.$v.formContent.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    organizationErrors () {
      const { $dirty, required } = this.$v.formContent.organization
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    projectErrors () {
      if (this.selectedWatchRuleType.key !== 'projectActivity') return []
      const { $dirty, required } = this.$v.formContent.project
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canSave () {
      if (this.$isCreationRoute) return !this.$v.$invalid
      return !this.$v.$invalid && this.$hasDataChanged('formContent')
    },
    filterTree () {
      return {
        Action: this.tags.action || [],
        Entity: this.tags.entity || [],
      }
    },
  },
  async created () {
    await Promise.all([
      ...(this.$isCreationRoute ? [] : [this.GET_WATCH_RULE({ canonical: this.$route.params.watchRuleCanonical })]),
      this.FETCH_AVAILABLE({ keyPath: 'projects' }),
      this.FETCH_AVAILABLE({ keyPath: 'tags' }),
    ])
    if (!this.$isCreationRoute) this.populateForm()
    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('notifications', [
      'CREATE_WATCH_RULE',
      'DELETE_WATCH_RULE',
      'UPDATE_WATCH_RULE',
      'GET_WATCH_RULE',
    ]),
    getFiltersArray () {
      if (_.isEmpty(this.selectedFilters)) return [{ operation: '*', entity: '*' }]

      const actionFilters = _.filter(this.selectedFilters, (path) => path.startsWith('Action')).map((path) => path.split('.').pop())
      const entityFilters = _.filter(this.selectedFilters, (path) => path.startsWith('Entity')).map((path) => path.split('.').pop())

      if (_.isEmpty(actionFilters)) {
        return entityFilters.map((entity) => ({ entity, operation: '*' }))
      }

      if (_.isEmpty(entityFilters)) {
        return actionFilters.map((action) => ({ entity: '*', operation: action }))
      }

      return actionFilters.map((action) => entityFilters.map((entity) => ({ entity, operation: action }))).flat()
    },
    async createWatchRule () {
      this.saving = true
      const { name, muted } = this.formContent
      const watchRule = {
        name,
        canonical: this.$getSlug(name),
        organization_canonical: this.formContent.organization,
        ...(this.selectedWatchRuleType.key === 'projectActivity'
          ? {
              project_canonical: this.formContent.project,
            }
          : {}),
        muted,
        filters: this.getFiltersArray(),
      }
      this.CREATE_WATCH_RULE({ watchRule, $router: this.$router })
      this.saving = false
    },
    async updateWatchRule () {
      this.saving = true
      const { name, muted } = this.formContent
      const watchRule = {
        name,
        muted,
        filters: _.cloneDeep(this.watchRule.filters),
      }
      await this.UPDATE_WATCH_RULE({ watchRuleCanonical: this.watchRule.canonical, watchRule })
      this.$setOriginalData('formContent')
      this.saving = false
    },
    async onDeleteConfirm () {
      this.deleting = true
      await this.DELETE_WATCH_RULE({ watchRuleCanonical: this.watchRule.canonical })
      this.deleting = false
      this.$toggle.showDeleteDialog(false)
      this.$router.push({ name: 'userWatchRules' })
    },
    setFormContentFromStore () {
      this.formContent = {
        name: this.watchRule.name,
        organization: this.watchRule.organization_canonical,
        project: this.watchRule.project_canonical,
        muted: this.watchRule.muted,
      }
    },
    populateFilters () {
      const filterPairs = this.watchRule.filters.map(({ entity, operation }) => {
        if (entity === '*' && operation === '*') return []
        if (entity === '*') return [`Action.${operation}`]
        if (operation === '*') return [`Entity.${entity}`]
        return [
          `Action.${operation}`,
          `Entity.${entity}`,
        ]
      })
      this.selectedFilters = _.uniq(_.flatten(filterPairs))
    },
    populateForm () {
      const type = this.watchRule.project_canonical ? 'projectActivity' : 'eventTracking'
      this.selectedWatchRuleType = _.find(this.$static.watchRuleTypes, { key: type })
      this.setFormContentFromStore()
      this.populateFilters()
      this.$setOriginalData('formContent')
    },
    changeType () {
      this.selectedWatchRuleType = null
      this.clearForm()
    },
    clearForm () {
      this.formContent = _.cloneDeep(EMPTY_FORM_CONTENT)
      this.$v.$reset()
    },
    onSave () {
      if (this.$isCreationRoute) return this.createWatchRule()
      this.updateWatchRule()
    },
    onCancel () {
      if (this.$isCreationRoute) return this.$router.push({ name: 'userWatchRules' })
      this.setFormContentFromStore()
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Watch rule',
        description: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        changeType: 'Change rule type',
        confirmDeleteHeader: 'Delete watch rule?',
        confirmDeleteSentence: 'Are you sure you want to delete this watch rule?',
        createWatchRule: 'Create watch rule',
        fields: {
          ruleName: {
            label: 'Rule name',
            hint: 'Enter a descriptive name for the watch rule',
          },
          organization: {
            label: '@:Organization',
            hint: 'Select the organization for this rule. A rule is always scoped to a single organization.',
          },
          project: {
            label: '@:Project',
            hint: 'Select the project to watch',
          },
          mute: {
            label: 'Mute',
            hint: 'Prevent the watch rule from emitting notifications',
          },
        },
        sections: {
          type: {
            title: 'Select watch rule type',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Project activity',
            description: 'Get notified when changes are made within a project.',
          },
          event: {
            name: 'Event tracking',
            description: 'Get notified for specific events within an organization.',
          },
        },
        watchRuleType: 'Watch rule type',
        when: 'When',
        eventIsFired: 'an event is fired that matches all the following filters',
        then: 'Then',
        sendNotification: 'send me an in app notification',
      },
      es: {
        title: 'Regla de monitoreo',
        description: 'Las reglas de monitoreo te ayudan a mantenerte informado enviando notificaciones cada vez que se realizan cambios en un proyecto o ocurre un evento específico.',
        changeType: 'Cambiar tipo de regla',
        confirmDeleteHeader: '¿Eliminar regla de monitoreo?',
        confirmDeleteSentence: '¿Estás seguro de que deseas eliminar esta regla de monitoreo?',
        createWatchRule: 'Crear regla de monitoreo',
        fields: {
          ruleName: {
            label: 'Nombre de la regla',
            hint: 'Ingresa un nombre descriptivo para la regla de monitoreo',
          },
          organization: {
            label: '@:Organization',
            hint: 'Selecciona la organización para esta regla. Una regla siempre está limitada a una sola organización.',
          },
          project: {
            label: '@:Project',
            hint: 'Selecciona el proyecto a monitorear',
          },
          mute: {
            label: 'Silenciar',
            hint: 'Evita que la regla de monitoreo emita notificaciones',
          },
        },
        sections: {
          type: {
            title: 'Selecciona el tipo de regla de monitoreo',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Actividad del proyecto',
            description: 'Recibe notificaciones cuando se realizan cambios dentro de un proyecto.',
          },
          event: {
            name: 'Seguimiento de eventos',
            description: 'Recibe notificaciones para eventos específicos dentro de una organización.',
          },
        },
        watchRuleType: 'Tipo de regla de monitoreo',
        when: 'Cuando',
        eventIsFired: 'se dispara un evento que coincide con todos los siguientes filtros',
        then: 'Entonces',
        sendNotification: 'envíame una notificación en la aplicación',
      },
      fr: {
        title: 'Règle de notification',
        description: `Les règles de notification vous aident à rester informé en vous envoyant des notifications chaque fois qu'un changement est apporté à un projet ou qu'un événement spécifique se produit.`,
        changeType: 'Changer le type de règle',
        confirmDeleteHeader: 'Supprimer la règle de notification ?',
        confirmDeleteSentence: 'Voulez-vous vraiment supprimer cette règle de notification ?',
        createWatchRule: 'Créer une règle de notification',
        fields: {
          ruleName: {
            label: 'Nom de la règle',
            hint: 'Entrez un nom descriptif pour la règle de notification',
          },
          organization: {
            label: '@:Organization',
            hint: `Sélectionnez l'organisation pour cette règle. Une règle est toujours limitée à une seule organisation.`,
          },
          project: {
            label: '@:Project',
            hint: `Sélectionnez le projet à surveiller`,
          },
          mute: {
            label: 'Désactivée',
            hint: `Empêchez la règle de notification d'émettre des notifications`,
          },
        },
        sections: {
          type: {
            title: 'Sélectionner le type de règle de notification',
          },
          general: {
            title: '@:general',
          },
          configuration: {
            title: '@:configuration',
          },
        },
        watchRuleTypes: {
          project: {
            name: 'Activité du projet',
            description: `Recevez des notifications lorsque des changements sont apportés à un projet.`,
          },
          event: {
            name: 'Suivi des événements',
            description: `Recevez des notifications pour des événements spécifiques au sein d'une organisation.`,
          },
        },
        watchRuleType: 'Type de règle de notification',
        when: 'Quand',
        eventIsFired: 'un événement est déclenché qui correspond à tous les filtres suivants',
        then: 'Alors',
        sendNotification: `envoyez-moi une notification dans l'application`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.change-type__btn {
  color: cy-get-color("secondary");
  font-weight: $font-weight-bolder;
  cursor: pointer;
}

.watch-rule-type__label {
  color: cy-get-color("grey", "dark-2");
}

.watch-rule-types {
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    padding-left: 8px;
    border: 1px solid cy-get-color("primary", "light-4");
    border-radius: 4px;
    background-color: cy-get-color("white");
    color: cy-get-color("primary");
    cursor: pointer;

    &:hover {
      background: cy-get-color("grey", "light-4");
    }
  }

  &--selected {
    padding-left: 0;
  }
}
</style>
